$(document).ready(function () {
  /* ==============******==============
        ||Login Js Start HERE||
       ==============******==============
    */

  $(".login-form .form-control").focus(function () {
    $(this).parent(".input-item").addClass("focus-input");
    $(this).parent(".input-item").removeClass("error-input");
  })
  $(".login-form .form-control").focusout(function () {
    if ($(this).val() === "") {
      $(this).parent(".input-item").removeClass("focus-input");
      $(this).parent(".input-item").addClass("error-input");
    } else {
      $(this).parent(".input-item").removeClass("error-input");
    }
  });


  /* ==============******=========================
    ||Expand Paragraph Read More Div JS Start HERE||
     ==============******===========================
  */
  // $(document).on("click", ".about.read-more", function () {
  //   if ($(this).children().hasClass("icon-plus")) {
  //     $(this).parent(".section-item").children(".paragraph").removeClass("expand-para");
  //     $(this).children(".icon-plus").addClass("icon-minus").removeClass("icon-plus");
  //     var childrenList = $(this).children();
  //     $(this).text("Read less").append(childrenList);
  //   } else {
  //     $(this).parent(".section-item").children(".paragraph").addClass("expand-para");
  //     $(this).children(".icon-minus").addClass("icon-plus").removeClass("icon-minus");
  //     var childrenList = $(this).children();
  //     $(this).text("Read More").append(childrenList);

  //   }
  // });

  // $(document).on("click", ".more-reviews .read-more", function () {
  //   if ($(this).children().hasClass("icon-plus")) {
  //     $(this).children(".icon-plus").addClass("icon-minus").removeClass("icon-plus");
  //     var childrenList = $(this).children();
  //     $(this).text("Read less Reviews").append(childrenList);
  //     $(this).parents('.more-reviews').siblings('.review-sections').addClass('show-all-items');

  //     var totalHeight = 0;
  //     $(".review-sections .section-item").each(function (index) {
  //       if (index <= 2) {
  //         totalHeight += $(this).outerHeight()
  //       }
  //     })
  //     $(".review-sections").height(totalHeight);

  //   } else {
  //     $(this).children(".icon-minus").addClass("icon-plus").removeClass("icon-minus");
  //     var childrenList = $(this).children();
  //     $(this).text("Read less Reviews").append(childrenList);
  //     $(this).parents('.more-reviews').siblings('.review-sections').removeClass('show-all-items');
  //     $(".review-sections").css('height', '');
  //   }

  // });

  /* ==============******=========================
    ||Expand Paragraph Read More Div JS End HERE||
     ==============******===========================
  */


  /* ==============******==============
      ||Windows Scroll Js Start HERE||
     ==============******==============
  */

  $(".activity-images-thum, .fs-gal-close").on("click", function () {
    $('body').toggleClass("position-fixed")
  })

  /* ==============******==============
    ||Windows Scroll Js End HERE||
   ==============******==============
*/

  /* ==============******==============
      ||Windows Resize Js Start HERE||
     ==============******==============
  */
  // $(window).resize(function () {
  //   if ($(window).width() < 576) {
  //     $(".owl-carousel-item").parent(".row").addClass("owl-carousel");
  //     $(".sections-items").removeClass("container");
  //     $('.sections-items').addClass("container-fluid");
  //     startCarousel();
  //   } else {
  //     this.location.reload();
  //   }
  // });
  /* ==============******==============
    ||Windows Resize Js Start HERE||
   ==============******==============
*/


  /* ==============******=================
      ||Moible View add slider Start HERE||
     ==============******=================
  */
  // if ($(window).width() < 576) {
  //   $(".owl-carousel-item").parent(".row").addClass("owl-carousel");
  //   $(".sections-items").removeClass("container");
  //   $('.sections-items').addClass("container-fluid");
  //   startCarousel();
  // }
  /* ==============******=================
    ||Moible View add slider End HERE||
   ==============******==================
   */

  /* ==============******=====================
      ||Top Destinations Filter Js Start HERE||
     ==============******=====================
     */

  $('.filter').not('.' + $(".filter-button.active").attr('data-filter')).hide();
  $('.filter').filter('.' + $(".filter-button.active").attr('data-filter')).show();
  $(".filter-button").click(function () {
    var value = $(this).attr('data-filter');
    $(".filter").not('.' + value).hide();
    $(".filter").not('.' + value).parents(".owl-item").hide();
    $('.filter').filter('.' + value).show();
    $(".filter").filter('.' + value).parents(".owl-item").show();
    $(".filter-button").removeClass("active")
    $(this).addClass("active");
  });

  $(".filter").filter('.europe').parents(".owl-item").show();
  $(".filter").filter('.europe').parents(".owl-item").addClass("active");
  $(".filter").not('.europe').parents(".owl-item").hide();
  $(".filter").not('.europe').parents(".owl-item").removeClass("active");

  /* ==============******=====================
      ||Top Destinations Filter Js Start HERE||
     ==============******=====================
     */



  /* ==============******==============
      ||Date picker Js Start HERE||
     ==============******==============
  */
  // $("#request-guide-date").flatpickr({
  //   enableTime: false,
  //   dateFormat: "m-d-Y",
  //   disableMobile: false,
  //   static: true
  // });
  // $("#request-guide-time").flatpickr({
  //   enableTime: true,
  //   noCalendar: true,
  //   dateFormat: "H:i",
  //   time_24hr: true,
  //   disableMobile: false,
  //   static: true
  // });

  /* ==============******==============
      ||Date picker Js End HERE||
     ==============******==============
  */

  /* ==============******==============
    ||Price Calculation JS Start Here||
     ==============******==============
   */
  var totalPrice = $(".price-value")[0];
  var personPrice = $(".price-value").attr("data-pvalue");
  $(".minus").on("click", function () {
    if ($("#request-guide-guest").val() > 0) {
      $("#request-guide-guest").val(function (i, oldval) {
        let total = parseInt(totalPrice.innerText) - parseInt(personPrice);
        totalPrice.innerText = total;
        return --oldval;
      });
    }
  });
  $(".plus").on("click", function () {
    $("#request-guide-guest").val(function (i, oldval) {
      let total = parseInt(totalPrice.innerText) + parseInt(personPrice);
      totalPrice.innerText = total;
      return ++oldval;
    });
  });
  /* ==============******==============
     ||Price Calculation JS End Here||
      ==============******==============
    */
});




$(window).on('load', function () {
  var totalHeight = 0;
  if ($(".review-sections .section-item").length > 3) {
    // $(".review-sections .section-item").each(function (index) {
    //   if (index <= 2) {
    //     totalHeight += $(this).outerHeight()
    //   }
    // })
    // $(".review-sections").height(totalHeight);
    $(".more-reviews").show();
  }
});
/* ==============******=====================
      ||Carousel Config Function Js End HERE||
     ==============******=====================
  */
function startCarousel(className, itemNum) {
  // $(".our-guide-carousel.owl-carousel").owlCarousel({
  //   navigation: false, // Show next and prev buttons
  //   slideSpeed: 100,
  //   paginationSpeed: 100,
  //   autoplay: false,
  //   itemsDesktop: false,
  //   itemsDesktopSmall: false,
  //   itemsTablet: false,
  //   itemsMobile: false,
  //   loop: true,
  //   nav: false,
  //   margin: 0,
  //   items: 1.58,
  //   responsive: {
  //     0: {
  //       items: 1.1,
  //     },
  //     400: {
  //       items: 1.58,
  //     },
  //   }
  // });
  // $(".activity-image-owl-carousel").owlCarousel({
  //   navigation: false, // Show next and prev buttons
  //   slideSpeed: 100,
  //   paginationSpeed: 100,
  //   autoplay: false,
  //   itemsDesktop: false,
  //   itemsDesktopSmall: false,
  //   itemsTablet: false,
  //   itemsMobile: false,
  //   loop: true,
  //   nav: false,
  //   margin: 0,
  //   items: 1.25,
  //   responsive: {
  //     0: {
  //       items: 1.1,
  //     },
  //     400: {
  //       items: 1.35,
  //     },
  //   }
  // });
  // $(".blog-carousel.owl-carousel").owlCarousel({
  //   navigation: false, // Show next and prev buttons
  //   slideSpeed: 100,
  //   paginationSpeed: 100,
  //   autoplay: false,
  //   itemsDesktop: false,
  //   itemsDesktopSmall: false,
  //   itemsTablet: false,
  //   itemsMobile: false,
  //   loop: true,
  //   nav: false,
  //   margin: 0,
  //   items: 2,
  //   responsive: {
  //     0: {
  //       items: 1.5,
  //     },
  //     400: {
  //       items: 2,
  //     },
  //   }
  // });
  // $(".owl-carousel").owlCarousel({
  //   navigation: false, // Show next and prev buttons
  //   slideSpeed: 100,
  //   paginationSpeed: 100,
  //   autoplay: false,
  //   itemsDesktop: false,
  //   itemsDesktopSmall: false,
  //   itemsTablet: false,
  //   itemsMobile: false,
  //   items: 2.25,
  //   loop: true,
  //   nav: false,
  //   margin: 0,
  //   responsive: {
  //     0: {
  //       items: 2.1,
  //     },
  //     400: {
  //       items: 2.25,
  //     },
  //   }
  // });
  /* ==============******=====================
      ||Carousel Config Function Js End HERE||
     ==============******=====================
  */
}
